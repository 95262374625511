import React from 'react';
import { Tooltip } from 'wix-ui-tpa/cssVars';

import { Chevron } from './Chevron';

import { st, classes } from './Accordion.st.css';
import { classes as styles } from '../../components/ParticipantPage/Widget/components/StepsListAsTiles/StepsListAsTiles.st.css';
import { ALIGNMENT } from './constants';
import { FCWithChildren } from '@wix/challenges-web-library';
import { IAccordionProps } from './Accordion';

export interface INewAccordionProps extends IAccordionProps {
  isMobile?: boolean;
  toggleOpen: () => void;
  isRTL?: boolean;
}

export const NewAccordion: FCWithChildren<INewAccordionProps> = (props) => {
  const {
    isMobile,
    opened,
    toggleOpen,
    id,
    prefixIcon,
    tooltipText,
    tooltipDisabled,
    title,
    subtitle,
    className,
    disabled,
    align = ALIGNMENT.left,
    children,
    isRTL,
  } = props;

  return (
    <section
      className={st(
        classes.root,
        {
          state: opened ? 'opened' : 'closed',
          mobile: isMobile,
          disabled,
          align,
        },
        className,
        classes.newAccordion,
      )}
      dir={isRTL ? 'rtl' : 'auto'}
    >
      <div
        className={classes.buttonWrap}
        style={{
          marginLeft: prefixIcon ? '12px' : '0px',
        }}
      >
        <button
          type="button"
          aria-expanded={opened}
          aria-controls={id}
          id={`accordion-${id}`}
          className={classes.newTitleBar}
          onClick={toggleOpen}
          data-hook={props['data-hook']}
        >
          {prefixIcon ? (
            <Tooltip
              content={tooltipText}
              placement="top"
              appendTo="window"
              maxWidth={250}
              disabled={!!tooltipDisabled || !tooltipText}
            >
              <span className={classes.prefixIcon}>{prefixIcon}</span>
            </Tooltip>
          ) : null}
          <div className={classes.title} dir="auto">
            <p className={classes.titleText} dir="auto">
              {title}
            </p>
            {subtitle ? (
              <>
                <span className={styles.srOnly}>.</span>
                <p className={classes.subtitle}>{subtitle}</p>
              </>
            ) : null}
          </div>
          <span
            className={classes.icon}
            onClick={() => {
              props.onIconClick ? props.onIconClick() : toggleOpen();
            }}
          >
            {!props.disabled && <Chevron />}
          </span>
        </button>
      </div>

      {opened && (
        <div
          id={id}
          role="region"
          aria-labelledby={`accordion-${id}`}
          aria-hidden={!opened}
          className={classes.content}
        >
          {children}
        </div>
      )}
    </section>
  );
};
